import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import {userSelector} from "../../store/slices/userSlice";
// import {io} from "socket.io-client"
import { socket } from '../../socket';

import ChatContainer from "../ChatContainer";
import AddConversationContainer from "../AddConversationContainer";
import Conversation from "../../components/Conversation";

import "./chats.css"
import instance from '../../api.config';

function Chats() {
    const {currentUser} = useSelector(userSelector);
    const [currentChat, setCurrentChat] = useState(null);
    const [list, setList] = useState([]);
    const [clicked, setClicked] = useState(false);
    const [arrivalMessage, setArrivalMessage] = useState(null);
    const [active, setActive] = useState(false);
    // const socket = useRef(io(process.env.REACT_APP_SOCKET_URL));

    const handleSubmit = () => {
        setClicked((prevClicked) => !prevClicked);
    }

    const changeChat = (chat) => {
        setClicked(false)
        setCurrentChat(chat)

        if(chat){
            localStorage.setItem('currentChatId', chat?.id);
        }
            
    }
    // socket.current = io(process.env.REACT_APP_SOCKET_URL);

    useEffect(() => {
         // { withCredentials: true, transports: ["websocket"] }
        
        if (!socket.hasListeners('chatCreated')) {
            socket.on('chatCreated', (prop) => {

                setList((prev) => [prop, ...prev]);
                console.log('Чат додано!\nСторінка оновлена!');
            });
        }
           

        // if (!socket.hasListeners('messageCreated')) {
            socket.on("messageCreated", data => {
                setArrivalMessage({
                    chatId: data?.chatId
                })
            })
        // }
          

    }, []);


    useEffect(() => {
        async function fetchData() {
            try {
                const response = await instance.get('/getChats/' + currentUser.id)
                console.log('getChats :>> ', response);
                setList(response.data)
            } catch (e) {
                console.log(e)
            }
        }

        fetchData().then()
    }, [currentUser.id, arrivalMessage])

    console.log('object :>> ', list);
    return (
        <div className='d-flex flex-grow-1'>
            <div className="chats-container">
                <div className="left-box">
                    <button className="add-button" onClick={handleSubmit}>+</button>
                    {list.map((c) => (
                        <Conversation key={c.id} active={active} onClick={() => {changeChat(c); setActive(c.id)}} conversation={c} currentUser={currentUser}/>
                    ))}
                </div>
                <div className='d-flex flex-grow-1'>
                    {!clicked ? <>
                            <ChatContainer
                                currentChat={currentChat}
                                currentUser={currentUser}/> </> :
                        <AddConversationContainer currentUser={currentUser}/>}
                </div>
            </div>
        </div>
    );
}

export default Chats;