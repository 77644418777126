import API from "./api";
import store from "./store";
import { logoutUser } from "./store/slices/userSlice";

const instance = API;

// створюємо перехватчик запитів
// який до кожного запиту додає accessToken з localStorage
instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${sessionStorage.getItem("accessToken")}`
    return config
  }
)


// створюємо перехватчик відповідей
// який у разі недійсного accessToken намагається його оновити
// і передайте запит з оновленим accessToken
instance.interceptors.response.use(
  // у разі дійсного accessToken нічого не робимо:
  (config) => {
    return config;
  },
  // у разі простроченого accessToken намагаємося його оновити:
  async (error) => {

   if(error.response?.data?.message){
     error.message = error.response?.data?.message;
   }

   // запобігаєм зациклювальному запиту, додаючи властивість _isRetry
   const originalRequest = {...error.config};
   originalRequest._isRetry = true; 
    if (
      // перевіряємо, що помилка саме з-за недійсного accessToken
      error.response.status === 401 && 
      // перевіряю, що запит не повторний
      error.config &&
      !error.config._isRetry
    ) {
      try {
        // запит на оновлення токенів
        //const resp = await instance.get("/api/refresh");
        // зберігаємо новий accessToken в sessionStorage
        //sessionStorage.setItem("token", resp.data.accessToken);
        // переправляємо запит з оновленим accessToken
        store.dispatch(logoutUser());

        return instance.request(originalRequest);
      } catch (error) {
        console.log("AUTH ERROR");
      }
    }
    // випадково, якщо виникла друга помилка (не пов'язана з авторізацією)
    // прокину цю помилку
    throw error;
  }
);

export default instance;
