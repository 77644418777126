import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.REACT_APP_SOCKET_URL; // process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:4000';

const socket = io(URL);

socket.on('disconnect', (reason) => {
    console.log(`Socket disconnected by reason ${reason}`);
    if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.removeAllListeners(); // for test
        socket.connect();
    }
    // else the socket will automatically try to reconnect
});

export { socket };