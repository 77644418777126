import { jwtDecode } from 'jwt-decode';

export default function useDepartmentAuth() {
    const token = sessionStorage.getItem('accessToken');
    let decoded = null;
    if(token)
        decoded = jwtDecode(token);

    const department = {
        decoded: decoded,
        token:token
    }

    return {
        department
    };
}
