import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {userSelector, getUser} from "../../store/slices/userSlice";

const Card = () => {
    const {currentUser} = useSelector(userSelector)
    const [user, setUser] = useState(null)

    useEffect(() => {
        if (currentUser) {
            setUser(currentUser);
        }
    }, [currentUser]);

    if (!user) return (
        <div>
            <i className="fa fa-sign-in-alt mx-1" />
            <a href="/login" style={{textDecoration: "none", color: "white"}}>
                 Log In
            </a>
        </div>
    )

    const imageBaseUrl = user.image ? process.env.REACT_APP_UPLOAD_PATH_UI + user.image : "https://res.cloudinary.com/dluwizg51/image/upload/v1650639365/no-pic-ava_ac9buw.jpg";


    return (
        <div className="text-center">
            <img className="rounded-circle border"
                 src={imageBaseUrl} style={{height: "125px", width: "125px"}}/>
            <hr />
            <h3 style={{whiteSpace: 'normal'}} className="text-break">{user.nickname}</h3>
            <span className="lead text-break" style={{fontSize: "19px", whiteSpace: 'normal'}}>{user.email}</span>
        </div>
    );
};
export default Card;