import React, {useEffect, useState, useRef} from "react";
import {BallTriangle} from 'react-loader-spinner'
import instance from "../../api.config";
import {io} from "socket.io-client";
import './style.css';
import { socket } from "../../socket";

const Conversation = ({conversation, currentUser, onClick, active }) => {
    const [isRead, setIsRead] = useState(false);
    const [arrivalMessage, setArrivalMessage] = useState(null)
    // const socket = useRef(io(process.env.REACT_APP_SOCKET_URL));

    // socket.current = io(process.env.REACT_APP_SOCKET_URL);

    useEffect(() => {
        //{ withCredentials: true, transports: ["websocket"] }
        // console.log('before hasListeners messageCreated :>> ');
        // if (!socket.hasListeners('messageCreated')) {
            // console.log('after hasListeners messageCreated :>> ');
            socket.on("messageCreated", data => {
                setArrivalMessage({
                    chatId: data?.chatId
                })
                setIsRead(false);
            })
        // }
       
    }, [])

    if (!conversation.sender) return <BallTriangle
        radius="1"
        color="white"
    />

    const imageBaseUrl = conversation.sender.image ? process.env.REACT_APP_UPLOAD_PATH_UI + conversation.sender.image : "https://res.cloudinary.com/dluwizg51/image/upload/v1650639365/no-pic-ava_ac9buw.jpg";
    
    function getMainURL(main_url){
        const url = new URL(main_url);
        let originWithPathNameUrl = '';
        
        if(url?.pathname !== '/' || url?.pathname === undefined) 
        {
            originWithPathNameUrl = url.host + '/' + url.pathname.split("/")[1];
        }
        else{
            originWithPathNameUrl = url.host;
        }
    
        return originWithPathNameUrl;
    }

    const textForIcon = (nickname) => {
        if(!nickname) return "NF";

        if(nickname.length < 4) return nickname.toUpperCase();

        return nickname[0].toUpperCase();
    }

    return (
        <div onClick={() => { onClick(); setIsRead(true); }} className="cursor-pointer chat-item transition" style={{backgroundColor: active ===  conversation.id ? 'rgb(255 255 255 / 15%)' : ''}} >
            {conversation?.sender ? <>
                {/* <img className="rounded-circle contacts-image"
                src={imageBaseUrl} /> */}
                {(conversation?.newMsgCount && !isRead) ? <div className="badge-place-holder"><span className="badge badge-light badge-conversation">{conversation.newMsgCount}</span></div> : ''}
                <div className="d-flex info-chat" >
                    <div className="icon-text">{textForIcon(conversation.sender.nickname)}</div>
                    <div className="text-data">
                        <p style={{ fontSize: 'medium', padding: '0px 5px' }} className="text-white mb-0">{conversation.sender.nickname}</p>
                        {conversation.sender.titleCompany ?
                            <p style={{ fontSize: '14px', padding: '0px 5px' }} className="text-white mb-0 title-company show-all">{conversation.sender.titleCompany}</p>
                            :
                            <p style={{ padding: '0px 5px' }} className="main-url text-white text-break show-all">
                                {getMainURL(conversation.sender.main_url)}
                            </p>
                        }
                    </div>
                </div>
            </> :
                <></>}
        </div>
    );
    
};
export default Conversation;