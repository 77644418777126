import React, {useState, useEffect} from 'react';

import "./container.css"
import instance from "../../api.config";

function AddConversationContainer({currentUser}) {
    const [list, setList] = useState([])

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await instance.get('/getNewConversations')
                setList(response.data)
            } catch (e) {
                console.log(e)
            }
        }

        fetchData().then()
    }, [])

    const handleClick = async (id) => {
        const post = {
            senderId: currentUser.id,
            receiverId: id
        }
        instance.post('/createChat', post).then(() => {
            window.location.reload()
        })
    }
    return (
        <div>
            <div className="message-container">
                <div className="shadow p-3 bg-white rounded text-center">
                    <p className="lead">Add new Conversation</p>
                    <hr/>
                    <div className="users-box text-start">
                        <div>
                            {list.map((c) => (
                                <div className="my-2">
                                    <img
                                        className="user-image"
                                        src={c.image ? process.env.REACT_APP_UPLOAD_PATH_UI + c.image : "https://res.cloudinary.com/dluwizg51/image/upload/v1650639365/no-pic-ava_ac9buw.jpg"}
                                        alt=""
                                    />
                                    <span className="user-name">{c.nickname}</span>
                                    <i style={{cursor: "pointer"}}
                                       className="mx-2 fas fa-plus-circle"
                                       onClick={() => handleClick(c.id)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddConversationContainer;