import React, { useEffect, useRef, useState } from 'react';
import Message from "../../components/Message";

import "./chatcontainer.css"
import instance from "../../api.config";
import { socket } from "../../socket";

function ChatContainer({ currentChat, currentUser }) {
    const [messages, setMessages] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newMessage, setNewMessage] = useState("")
    const [image, setImage] = useState("")
    const [arrivalMessage, setArrivalMessage] = useState(null)
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageBase64, setSelectedImageBase64] = useState(null);
    const scrollRef = useRef();
    const scrollContainerRef = useRef();
    const fileInputRef = useRef(null);
    const [currentUserData, setCurrentUserData] = useState("");
    const [receiverUser, setReceiverUser] = useState("");
    const [newMsgCount, setNewMsgCount] = useState(0);


    useEffect(() => {
        //{ withCredentials: true, transports: ["websocket"] }
        console.log('before hasListeners getMessage =-----!!!! :>> ');
        if (!socket.hasListeners('getMessage')) {
            console.log('after hasListeners getMessage -------!!!! :>> ');
            socket.on("getMessage", data => {
                setArrivalMessage({
                    ...data
                })
            });
        }


    }, [])

    useEffect(() => {
        if (arrivalMessage && currentChat?.users.includes(arrivalMessage.senderId.toString())) {
            setMessages((prev) => [...prev, arrivalMessage])
        }
    }, [arrivalMessage, currentChat])

    useEffect(() => {
        async function fetchData() {
            try {
                if (!currentChat?.id) return;
                const response = await instance.get('/getMessages/' + [currentChat?.id])
                console.log('response :>> ', response);
                setMessages(response.data.messages);
                setNewMsgCount(response.data.newMsgCount);
            } catch (e) {
                console.log(e)
            }
        }

        fetchData().then()
    }, [currentChat])

    useEffect(() => {
        const fetch = async () => {

            if (!currentChat?.id) return;

            if (messages.at(-1).text === arrivalMessage?.text) console.log('Need to do :>> ', 'getNewMessages');

            await instance.post('/supportReadMessages/', { chatId: currentChat?.id })
        }

        fetch();
        scrollOnNewMassege(newMsgCount);

    }, [messages])

    useEffect(() => {
        socket.emit("addUser", currentUser.id)
    }, [currentUser])

    useEffect(() => {

        socket.on('messageCreated', (prop) => {
            const { senderId, text, image, chatId } = prop
            const currentChatId = parseInt(localStorage.getItem('currentChatId'));

            if (chatId === currentChatId) {
                setMessages((prev) => [...prev, { id: Date.now(), senderId, text, image }]);
                scrollRef.current?.scrollIntoView();
                console.log('Сторінка оновлена!');
            }
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;


        if (newMessage.length > 255) {
            alert('Розмір поля вводу перевищує максимально допустимий розмір в 255 символів.')
            return;
        }

        if (newMessage.trim() !== "" || selectedImage) {

            setIsSubmitting(true);

            const formData = new FormData();
            formData.append('chatId', currentChat.id);
            formData.append('senderId', currentUser.id);
            formData.append('text', newMessage);
            formData.append('image', selectedImage);

            try {
                const response = await instance.post('/createMessage', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                const receiverId = currentChat?.users?.find((member) => member != currentUser.id);

                socket.emit('sendMessage', {
                    senderId: currentUser.id,
                    receiverId,
                    text: newMessage,
                    image: response.data.image,
                });

                setMessages([...messages, response.data]);
                setNewMessage('');
                setSelectedImage(null);
                fileInputRef.current.value = '';

                const messageData = {
                    chatId: parseInt(response.data.chatId),
                    senderId: parseInt(response.data.senderId),
                    text: response.data.text,
                    image: response.data.image,
                }
                const sendSecretMessageData = {
                    message: messageData,
                    nickname: currentUserData
                }

            } catch (e) {
                console.log(e);
                alert('Send message error' + '! ' + e.message)
            }
        }

        setTimeout(() => {
            setIsSubmitting(false);
        }, 1000);
    };

    const convertImageToBase64 = (image) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(image);
        });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    const getLastVisebalElem = () => {
        let sum = 0;
        let minSum = Number.MAX_SAFE_INTEGER;
        const scrollContainer = scrollContainerRef.current;

        if (!scrollContainer?.children) return null;

        let children = Array.from(scrollContainer.children);

        for (let i = 0; i < children.length; i++) {
            const child = children[i];

            minSum = scrollContainer.offsetHeight - sum;
            if (child?.offsetHeight)
                sum += child.offsetHeight

            if (minSum <= 0) {
                return i;
            }

        }

        return null;
    }

    const scrollOnNewMassege = (countOfNewMsg) => {
        const scrollContainer = scrollContainerRef.current;

        if (!scrollContainer?.children) return;

        // Отримати всі елементи з контейнера
        let children = Array.from(scrollContainer.children);
        let sum = 0;
        const lastIndex = getLastVisebalElem() - 2;
        const num = children.length - countOfNewMsg;

        // Вивести висоту кожного елемента

        for (let i = lastIndex; i < children.length; i++) {
            const child = children[i];

            if (i < num && child?.offsetHeight) {
                sum += child.offsetHeight
            }
        }

        if (!num)
            sum = 0;

        let targetScrollTop = sum;

        if (num === children.length || num === children.length - 1)
            targetScrollTop = scrollContainer.scrollHeight + 500;

        scrollContainer.scrollTop = targetScrollTop;
    }

    return (
        <div className='d-flex flex-grow-1'>
            <div className="message-container ">
                <hr />
                <div className="container-fluid ">
                    {
                        currentChat ? <>
                            <div className="row d-flex justify-content-center  align-items-center">
                                <fieldset ref={scrollContainerRef} className="chat-box">
                                    {messages.map((m => (
                                        <div ref={scrollRef} key={m.id}>
                                            <Message message={m} own={m.senderId == currentUser.id}
                                                ownImage={currentUser.image} image={image}
                                                currentUserData={currentUserData}
                                                receiverUser={receiverUser} />
                                        </div>
                                    )))
                                    }
                                </fieldset>
                            </div>
                            <div className="row d-flex justify-content-center align-items-center p-1">
                                <div className="col-6 text-end">
                                    <textarea placeholder="Send a message"
                                        className="send-message"
                                        name="newMessage"
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        value={newMessage}
                                        onKeyPress={handleKeyPress}
                                    />
                                </div>
                                <div className="col-3 d-flex justify-content-start align-items-center">

                                    <label className='image-label transition' htmlFor="fileInput">
                                        {selectedImage ? (
                                            <img src={selectedImageBase64} alt="" style={{ width: 60 }} />
                                        ) : (
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="30px"
                                                viewBox="0 0 24 24"
                                                width="30px"
                                                fill="#000000"
                                            >
                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                <path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z" />
                                            </svg>
                                        )}
                                    </label>
                                    <input
                                        id="fileInput"
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        onChange={async (e) => {
                                            setSelectedImage(e.target.files[0]);
                                            setSelectedImageBase64(e.target.files[0] ? await convertImageToBase64(e.target.files[0]) : null);
                                        }}
                                    />
                                    <button className="send-icon mx-2 transition" onClick={handleSubmit}>
                                        <i className={`fa ${isSubmitting ? 'fa-spinner rotating-container' : 'fa-paper-plane'}`} />
                                    </button>
                                </div>
                            </div>
                        </> : <span>Open a conversation to start a chat</span>}
                </div>
            </div>
        </div>
    );
}

export default ChatContainer;
