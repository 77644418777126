import React, { useEffect } from "react";
import moment from "moment-timezone"

import "./message.css";

function ImageComponent({ imageUrl, parentHeight }) {
    const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });

    useEffect(() => {

        const img = new Image();
        img.src = imageUrl;

        img.onload = () => {
            setDimensions({ width: img.width, height: img.height });
        };
    }, [imageUrl]);

    const setStylesByRatio = () => {

        if(dimensions.width / dimensions.height <= 5/3){
            return { maxWidth: "100%", height: '100%', objectFit: 'cover', position: 'relative', top: '50%', transform: 'translateY(-50%)' };
        }

        return { maxWidth: "100%", maxHeight: '100%', objectFit: 'cover', position: 'relative', top: '50%', transform: 'translateY(-50%)' };
    }

    return (
            <img 
                src={imageUrl}  
                alt="" 
                style={
                    dimensions.width < parentHeight && dimensions.height < parentHeight && dimensions.width && dimensions.height ?
                    setStylesByRatio() : // Фото меньше за батьківський елемент
                    {  maxWidth: "100%", maxHeight: "100%", objectFit: 'cover', position: 'relative', top: '50%', transform: 'translateY(-50%)' } // Фото більше за батьківський елемент
                    } 
            />
    );
}

export default function Message({ message, own, image, ownImage, currentUserData, receiverUser }) {
    const timeAgo = moment(moment(message.createdAt).tz('UTC')).fromNow()
    const ownImageBaseUrl = ownImage ? process.env.REACT_APP_UPLOAD_PATH_UI + ownImage : "https://res.cloudinary.com/dluwizg51/image/upload/v1650639365/no-pic-ava_ac9buw.jpg";
    const imageBaseUrl = image ? process.env.REACT_APP_UPLOAD_PATH_UI + image : "https://res.cloudinary.com/dluwizg51/image/upload/v1650639365/no-pic-ava_ac9buw.jpg";
    
    return (
        <div className={own ? "message own" : "message"}>
            <div className="messageTop">
                <div className="d-flex">
                    <img
                        className="messageImg"
                        src={own ? ownImageBaseUrl : imageBaseUrl}
                        alt=""
                    />
                    <div className="d-flex justify-content-center flex-column">
                        <span className="my-0">{own ? currentUserData.nickname : receiverUser.nickname}</span>
                        <span className="my-0 main-url">
                            {own ? '' :
                                receiverUser?.main_url
                                    ?.replace('http://', '')
                                    ?.replace('/', '')
                            }</span>
                    </div>
                </div>

            </div>
            
            <div style={{ display: 'flex' }}>
                {
                    message.image ?
                        <div 
                        onClick={() => window.open(process.env.REACT_APP_UPLOAD_PATH_UI + message.image, '_blank')} 
                        style={{ maxWidth: '900px', height: 300, marginTop: 10, backgroundColor: '#E2E2E2', borderRadius: 10, cursor: 'pointer' }}
                        >
                            <ImageComponent imageUrl={process.env.REACT_APP_UPLOAD_PATH_UI + message.image} parentHeight={300} />
                        </div>
                        : null

                }
            </div>
            
            <div className="d-flex">
                {
                    message.text ?
                    <pre className="messageText my-2">{message.text}</pre>
                    : null
                }
            </div>
            <div title={moment(message.createdAt).format('DD.MM.YYYY HH:mm:ss')} className="messageBottom">{timeAgo}</div>
        </div>
    );
}