import { Navigate } from "react-router-dom";
import useDepartmentAuth from "../hooks/useDepartmentAuth";

const RequireDepartmentAuth = ({ children }) => {
    const { department } = useDepartmentAuth();
    if (!department?.decoded) {
        return <Navigate to="/login" replace />;
    }

    return children;
};

export default RequireDepartmentAuth;
